<template>
    <div id="main-form" class="manager-form form-page">
        <Form v-if="!fetching" :mode="mode" :stored="data" />
        <div class="policy-box">
            <div
                class="item reveal-information"
                @click="onOpenRevealInformationPopup"
            >
                羅氏揭露訊息
            </div>
        </div>
    </div>
</template>

<script>
import Form from '@/components/Form.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    components: { Form },
    data() {
        return {
            data: undefined,
            fetching: false,
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        ...mapState({
            mode: (state) => state.form.mode,
        }),
    },
    methods: {
        ...mapMutations({
            setMode: 'form/setMode',
            setAEConfirm: 'form/setAEConfirm',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
            checkState: 'manager/checkState',
        }),
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
        async fetchData() {
            this.fetching = true;
            const PAYLOAD = {
                category: 'Case',
                function: 'find',
                successMsg: '資料已匯入',
                data: this.$route.params.id,
            };
            const RES = await this.apiClient(PAYLOAD);
            if (!RES) {
                this.$router.push({ name: 'Manager_Case_List' });
                return;
            }
            this.setAEConfirm(RES.data.aeConfirm);
            this.checkState(RES.data.state);
            let DATA = RES.data.data.data;
            this.data = DATA;
            if (!DATA) {
                DATA = RES.data;
                this.data = {
                    CTNumber: DATA.CTNumber,
                    title: DATA.title,
                    doctorName: DATA.doctorName,
                    doctorUCI: DATA.doctorUCI,
                    hospital: DATA.hospital,
                    doctorAvatar: DATA.doctorAvatar,
                };
            }
            this.fetching = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.policy-box {
    margin-left: 40px;
}
</style>
